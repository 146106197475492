import React, { useEffect, useState, useMemo } from 'react';
import { Card, Col, Form, Row, Modal, Button, Spinner } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SyscoreComponentCard from 'components/common/SyscoreComponentCard';
import { reactBootstrapDocsUrl } from 'helpers/utils';
import SyscoreCloseButton from 'components/common/SyscoreCloseButton';
import { Link } from 'react-router-dom';
import IconButton from 'components/common/IconButton';
import { DynamicForm } from 'components/common/dynamic-form';
import { useForm, useFieldArray } from 'react-hook-form';
import Swal from 'sweetalert2';
import { useSystemAuthorizationItem } from 'hooks/useSystemAuthorizationItem';

function SystemAuthorizationModal() {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      //test: [{ firstName: "Bill", lastName: "Luo" }]
      test: []
    }
  });
  const handleform = {
    register: register,
    setValue: setValue,
    getValues: getValues,
    errors: errors
  };
  const pageModal = useSystemAuthorizationItem();

  const [refs, setRefs] = React.useState();

  useMemo(async () => {
    setRefs(await pageModal.getRefs());
  }, []);

  useMemo(() => {
    reset({});
    if (pageModal?.data) {
      console.log('setValue model');
      Object.keys(pageModal?.data).map(function (key) {
        setValue(key, pageModal?.data[key]);
      });
    }
  }, [pageModal?.data]);

  const onSubmit = data => {
    console.log('data >>', data);
    if (data['id']) {
      pageModal.onUpdateById(data['id'], data);
    } else {
      pageModal.onCreate(data);
    }
    pageModal.onSetShowModalEdit(false);
  };

  return (
    <>
      <Modal
        show={pageModal.isShowModalEdit}
        onHide={() => pageModal.onSetShowModalEdit(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              System Authorization Items
            </Modal.Title>
            <SyscoreCloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => pageModal.onSetShowModalEdit(false)}
            />
          </Modal.Header>
          <Modal.Body>
            <>
              {refs && pageModal?.data && (
                <DynamicForm
                  handleform={handleform}
                  moduleName={'moduleName'}
                  control={control}
                  refs={refs}
                  data={pageModal?.data}
                />
              )}
            </>
          </Modal.Body>
          <Modal.Footer>
            <IconButton
              type="submit"
              variant="syscore-default"
              size="sm"
              icon={'check'}
              transform="shrink-3"
            >
              {pageModal?.updating && (
                <Spinner
                  animation="border"
                  variant="primary"
                  style={{ width: '1rem', height: '1rem' }}
                  className="mr-2 mt-0"
                />
              )}
              <span className="d-none d-sm-inline-block ms-1">Save</span>
            </IconButton>
            <IconButton
              variant="syscore-default"
              size="sm"
              icon="chevron-left"
              transform="shrink-3"
              className="mx-2"
              onClick={() => pageModal.onSetShowModalEdit(false)}
            >
              <span className="d-none d-sm-inline-block ms-1">Close</span>
            </IconButton>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default SystemAuthorizationModal;
