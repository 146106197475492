/* eslint-disable */
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { baseURL, apiBaseURL } from 'common-helpers/env-common';
const { REACT_APP_ENV } = process.env;
import AuthContext from './AuthContextBase';

const ProtectedRoute = ({ children, accessBy }) => {
    const { user } = useContext(AuthContext);

    if (accessBy == 'non-authenticated') {
        if (!user) {
            return children;
        }
    } else if (accessBy === 'authenticated') {
        if (user) {
            return children;
        }
    }
    //window.location.href = `${REACT_APP_ENV == 'local' ? '/landing' : baseURL}/?ts=${Date.now()}`;
    //return <Navigate to={`${REACT_APP_ENV == 'local' ? '/landing' : baseURL}/?ts=${Date.now()}`}></Navigate>;
    return <Navigate to="/landing"></Navigate>;
};

export default ProtectedRoute;
