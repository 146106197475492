import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Col, Form, Row, Button, Spinner } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import PageHeader from 'components/common/PageHeader';
import { useForm, useFieldArray } from 'react-hook-form';
import DocumentStaus from 'components/common/document-status';
import moment from 'moment';
import { useSystemAuthorization } from 'hooks/useSystemAuthorization';
import { useSystemAuthorizationItem } from 'hooks/useSystemAuthorizationItem';
import SystemAuthorizationItem from './system-authorization-item';
import SystemAuthorizationModal from './system-authorization-model';
import SystemAuthorizationDetail from './system-authorization-detail';
// import VendorDetail from './vendordetail';

const moduleName = 'system-authorization';
// const moduleNameTitle = 'Planing Detail';
// const moduleNameLower = moduleName[0].toLowerCase() + moduleName.slice(1);

const SystemAuthorizationDetails = () => {
  //const { gid } = match.params;
  let { id } = useParams();
  const navigate = useNavigate();

  const [indexes, setIndexes] = React.useState([]);
  const [counter, setCounter] = React.useState(0);
  const [reload, setReload] = React.useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      //test: [{ firstName: "Bill", lastName: "Luo" }]
      test: []
    }
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'test'
    }
  );

  const pageData = useSystemAuthorization();
  const pagedataItem = useSystemAuthorizationItem();

  const baseLink = '/system-authorization';

  //   const [memberaddressId, setMemberaddressId] = useState('');
  //   const [memberFarmId, setMemberFarmId] = useState('');

  // --- dynamic form init
  const [refs, setRefs] = React.useState();
  const handleform = {
    register: register,
    setValue: setValue,
    getValues: getValues,
    errors: errors
  };

  let data = [];
  let fieldRefs = [];

  useMemo(async () => {
    if (!id) {
      fieldRefs = await pageData.getRefs();
      setRefs(fieldRefs);
      console.log('fieldRefs', fieldRefs);
    }
  }, []);

  useMemo(async () => {
    await pageData.emptyData();

    if (id) {
      data = await pageData.onGetById(id);
      fieldRefs = await pageData.getRefs();
      setRefs(fieldRefs);

      pagedataItem.setParentId(id);
      addForm();
      console.log('datial data =>', data);
    }

    //setReload(false);
  }, [id]);

  const addForm = () => {
    console.log('add form');
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };

  useMemo(() => {
    reset({});
    if (pageData?.data) {
      console.log('setValue model');
      Object.keys(pageData?.data).map(function (key) {
        setValue(key, pageData?.data[key]);
      });
    }
  }, [pageData?.data]);

  const onSubmit = data => {
    console.log('data >>', data);

    //console.log('9999=88888')
    if (id != null) {
      pageData.onUpdateById(id, data);
    } else {
      pageData.onCreate(data);
    }
    setReload(true);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PageHeader title={pageData?.data?.code} titleTag="h5" className="mb-2">
          <p className="fs--1 mt-2">
            {moment(pageData?.data?.createdAt).format('LLLL')}
          </p>
          {/* <div>
            <strong className="me-2">Status: </strong>
            <SoftBadge pill bg="success" className="fs--2">
              {' '}
              Completed
              <FontAwesomeIcon
                icon="check"
                className="ms-1"
                transform="shrink-2"
              />
            </SoftBadge>
          </div> */}

          {/*
          <Spinner size="sm"
            style={{ width: '1rem', height: '1rem' }}
            className="mr-2 mt-0"
            color="primary" /> */}
          <div id="orders-actions" className="ms-auto text-end ps-0">
            <IconButton
              variant="syscore-default"
              size="sm"
              icon="chevron-left"
              transform="shrink-3"
              className="mx-2"
              onClick={() => navigate(baseLink)}
              // onClick={() => navigate(`${baseLink}`)}
            >
              <span className="d-none d-sm-inline-block ms-1">Back</span>
            </IconButton>
            <IconButton
              type="submit"
              variant="syscore-default"
              size="sm"
              icon={pageData?.updating ? '' : 'check'}
              transform="shrink-3"
            >
              {pageData?.updating && (
                <Spinner
                  animation="border"
                  variant="primary"
                  style={{ width: '1rem', height: '1rem' }}
                  className="mr-2 mt-0"
                />
              )}
              <span className="d-none d-sm-inline-block ms-1">Save</span>
            </IconButton>
          </div>
        </PageHeader>

        <Row className="g-2">
          <Col lg={12}>
            <SystemAuthorizationDetail
              register={register}
              setValue={setValue}
              handleform={handleform}
              moduleName={moduleName}
              control={control}
              data={pageData?.data}
              refs={refs}
            />
            {id && <SystemAuthorizationItem />}
          </Col>
          <Col lg={3}>
            <div className="sticky-sidebar">
              <Row className="gx-0 gy-1">
                {/* <DocumentStaus register={register} control={control} /> */}
                {/* <PaymentInfo register={register} control={control} />
                <EventOtherInfo register={register} control={control} /> */}
              </Row>
            </div>
          </Col>

          <Col>{/* <EventFooter /> */}</Col>
        </Row>
      </Form>
      <SystemAuthorizationModal />
    </>
  );
};

export default SystemAuthorizationDetails;
