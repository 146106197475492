import React, { useState, useEffect } from 'react';
import {
  Form,
  Label,
  FormGroup,
  FormText,
  FormFeedback,
  Row,
  Col
} from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment/moment';

//import FormGroupInput from "components/common/FormGroupInput";
//import FormInput from "components/common/FormInput";

function convertUTCToLocalDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
}

function convertLocalToUTCDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return date;
}

//for a select input type
const renderOptions = (options, defaultValue) => {
  return options.map(child => {
    if (child.guiValue == defaultValue) {
      return (
        <option key={child.guiValue} value={child.guiValue} selected>
          {child.itemLabel}
        </option>
      );
    } else {
      return (
        <option key={child.guiValue} value={child.guiValue}>
          {child.itemLabel}
        </option>
      );
    }
  });
};

const renderOptionsString = (options, defaultValue) => {
  return options.map(child => {
    if (child.stringValue == defaultValue) {
      return (
        <option key={child.stringValue} value={child.stringValue} selected>
          {child.itemLabel}
        </option>
      );
    } else {
      return (
        <option key={child.stringValue} value={child.stringValue}>
          {child.itemLabel}
        </option>
      );
    }
  });
};

export function DynamicForm({
  template,
  data,
  handleform,
  moduleName,
  control,
  refs,
  groupName
}) {
  const moduleNameLower = moduleName[0].toLowerCase() + moduleName.slice(1);
  let { register, setValue, getValues, errors } = handleform;

  useEffect(() => {
    if (errors) {
      console.log('errors ==> ', errors);
      console.log('errors ==> ', errors['accCode']);
    }
  }, [errors]);

  let groupfieldName = '';
  const renderFields = fields => {
    return fields.map(field => {
      let {
        fieldType,
        fieldName,
        name,
        required,
        value,
        validation,
        optionList,
        isRequire,
        isDisable,
        requireMessage,
        requireMessageLocale,
        xl,
        lg,
        md,
        sm,
        xs
      } = field;

      let fieldInvalidProps = {};

      /** bug!!! **/
      // if (!data[fieldName]) {
      //   setValue(fieldName, "")
      // }

      if (isRequire) {
        fieldInvalidProps = { isInvalid: !!(errors && errors[fieldName]) };
      }

      // let fieldRequireProps = {};
      // if (isRequire) {
      //     fieldRequireProps = { 'required': 'required' }
      // }
      // else {
      //     fieldRequireProps = {};
      // }

      let fieldRequireProps = {};
      if (isRequire) {
        fieldRequireProps = { required: true };
      }

      switch (fieldType) {
        case 'String':
          return (
            <Col lg={xl} xl={xl} md={xl}>
              <Form.Group controlId={fieldName}>
                <Form.Label>{name}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={name}
                  {...fieldInvalidProps}
                  {...register(fieldName, {
                    ...fieldRequireProps
                  })}
                  id={fieldName}
                  disabled={isDisable}
                  name={fieldName}
                  label={`${name}`}
                  defaultValue={data[fieldName]}
                />
                {/* <showRequireMessage /> */}
                <Form.Control.Feedback type="invalid">
                  {requireMessage}
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group controlId={fieldName}>
                                <Form.Label>{name}</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder={fieldName}
                                    {...register(fieldName)}
                                    id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]}
                                />

                            </Form.Group> */}

              {/* <FormInput
                                {...register(fieldName)}

                                control={control} as={FormGroupInput} id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]} /> */}
            </Col>
          );
        case 'StringNoEdit':
          return (
            <Col lg={xl} xl={xl} md={xl}>
              <Form.Group controlId={fieldName}>
                <Form.Label>{name}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={name}
                  {...fieldInvalidProps}
                  {...register(fieldName, {
                    ...fieldRequireProps
                  })}
                  id={fieldName}
                  name={fieldName}
                  label={`${name}`}
                  defaultValue={data[fieldName]}
                  readOnly
                />
                {/* <showRequireMessage /> */}
                <Form.Control.Feedback type="invalid">
                  {requireMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        case 'Text':
          return (
            <Col lg={12}>
              <Form.Group controlId={fieldName}>
                <Form.Label className="mt-3">{name}</Form.Label>
                <Form.Control.Feedback type="invalid">
                  {requireMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          );
        case 'DateTime': {
          //console.log('default date value :: ', moment(data[fieldName]).isValid() ? moment(data[fieldName]).toDate() : new Date())

          // var m = moment(data[fieldName]).isValid()
          //   ? moment(data[fieldName]).toDate()
          //   : new Date();
          // //m.utcOffset(0);
          // m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
          // m.toISOString();
          // m.format();

          setValue(
            fieldName,
            // m.toDate()
            moment(data[fieldName]).isValid()
              ? convertLocalToUTCDate(moment(data[fieldName]).toDate())
              : convertLocalToUTCDate(new Date())
          );
          return (
            <Col lg={xl} xl={xl} md={xl}>
              <Form.Group>
                <Form.Label>{name} </Form.Label>
                {/* <Form.Control
                                        as={
                                            <DatePicker />
                                        }
                                        control={control}
                                        name="date"
                                    /> */}

                {/* <Form.Control as={DatePicker} onChange={date => console.log(date)} /> */}

                <Controller
                  control={control}
                  id={fieldName}
                  name={fieldName}
                  {...fieldInvalidProps}
                  {...register(fieldName, {
                    ...fieldRequireProps
                  })}
                  // rules={{ required: true }}
                  defaultValue={
                    moment(data[fieldName]).isValid()
                      ? convertLocalToUTCDate(moment(data[fieldName]).toDate())
                      : convertLocalToUTCDate(new Date())
                  }
                  render={({ field }) => (
                    <DatePicker
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText={fieldName}
                      disabled={isDisable}
                      //onChange={e => field.onChange(e)}
                      //selected={field.value}
                      //startDate={convertUTCToLocalDate(startDate)}
                      //endDate={convertUTCToLocalDate(endDate)}
                      selected={convertLocalToUTCDate(field.value)}
                      onChange={date =>
                        field.onChange(convertLocalToUTCDate(date))
                      }
                    />
                  )}
                />

                {/* <Form.Control
                                    control={control}
                                    as={ReactDatePicker}
                                    onChange={date => console.log(date)}

                                    // onChange={(e) =>
                                    //     console.log(e.target.value)
                                    //     //    setDate(e.target.value)
                                    // }
                                    //selected={data[fieldName]}
                                    //type="date"
                                    placeholder={fieldName}
                                    {...fieldInvalidProps}
                                    {...register(fieldName)}
                                    // {...register(fieldName, {
                                    //     ...fieldRequireProps
                                    // })}
                                    id={fieldName}
                                    name={fieldName}
                                    label={`${name}`}
                                    defaultValue={data[fieldName]}
                                /> */}

                {/* <showRequireMessage /> */}
                <Form.Control.Feedback type="invalid">
                  {requireMessage}
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group controlId={fieldName}>
                                        <Form.Label>{name}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={fieldName}
                                            {...register(fieldName)}
                                            id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]}
                                        />

                                    </Form.Group> */}

              {/* <FormInput
                                        {...register(fieldName)}

                                        control={control} as={FormGroupInput} id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]} /> */}
            </Col>
          );
        }

        case 'DateTimeNoEdit': {
          //console.log('default date value :: ', moment(data[fieldName]).isValid() ? moment(data[fieldName]).toDate() : new Date())
          setValue(
            fieldName,
            moment(data[fieldName]).isValid()
              ? moment(data[fieldName]).toDate()
              : new Date()
          );
          return (
            <Col lg={xl} xl={xl} md={xl}>
              <Form.Group>
                <Form.Label>{name} </Form.Label>
                {/* <Form.Control
                                        as={
                                            <DatePicker />
                                        }
                                        control={control}
                                        name="date"
                                    /> */}

                {/* <Form.Control as={DatePicker} onChange={date => console.log(date)} /> */}

                <Controller
                  control={control}
                  id={fieldName}
                  name={fieldName}
                  {...fieldInvalidProps}
                  {...register(fieldName, {
                    ...fieldRequireProps
                  })}
                  // rules={{ required: true }}
                  defaultValue={
                    moment(data[fieldName]).isValid()
                      ? moment(data[fieldName]).toDate()
                      : new Date()
                  }
                  render={({ field }) => (
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      placeholderText={fieldName}
                      onChange={e => field.onChange(e)}
                      selected={field.value}
                      readOnly
                    />
                  )}
                />

                {/* <Form.Control
                                    control={control}
                                    as={ReactDatePicker}
                                    onChange={date => console.log(date)}

                                    // onChange={(e) =>
                                    //     console.log(e.target.value)
                                    //     //    setDate(e.target.value)
                                    // }
                                    //selected={data[fieldName]}
                                    //type="date"
                                    placeholder={fieldName}
                                    {...fieldInvalidProps}
                                    {...register(fieldName)}
                                    // {...register(fieldName, {
                                    //     ...fieldRequireProps
                                    // })}
                                    id={fieldName}
                                    name={fieldName}
                                    label={`${name}`}
                                    defaultValue={data[fieldName]}
                                /> */}

                {/* <showRequireMessage /> */}
                <Form.Control.Feedback type="invalid">
                  {requireMessage}
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group controlId={fieldName}>
                                        <Form.Label>{name}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={fieldName}
                                            {...register(fieldName)}
                                            id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]}
                                        />

                                    </Form.Group> */}

              {/* <FormInput
                                        {...register(fieldName)}

                                        control={control} as={FormGroupInput} id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]} /> */}
            </Col>
          );
        }

        case 'Time': {
          //console.log('default date value :: ', moment(data[fieldName]).isValid() ? moment(data[fieldName]).toDate() : new Date())
          setValue(
            fieldName,
            moment(data[fieldName]).isValid()
              ? moment(data[fieldName]).toDate()
              : new Date()
          );
          return (
            <Col lg={xl} xl={xl} md={xl}>
              <Form.Group>
                <Form.Label>{name} </Form.Label>

                <Controller
                  render={({ field }) => (
                    <DatePicker
                      selected={field.value}
                      onChange={date => {
                        field.onChange(date);
                      }}
                      className="form-control"
                      placeholderText="Select Time"
                      timeIntervals={1}
                      dateFormat="h:mm aa"
                      showTimeSelect
                      showTimeSelectOnly
                      {...field}
                    />
                  )}
                  name={fieldName}
                  control={control}
                  defaultValue={data[fieldName]}
                />

                {/* <showRequireMessage /> */}
                <Form.Control.Feedback type="invalid">
                  {requireMessage}
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group controlId={fieldName}>
                                        <Form.Label>{name}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={fieldName}
                                            {...register(fieldName)}
                                            id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]}
                                        />

                                    </Form.Group> */}

              {/* <FormInput
                                        {...register(fieldName)}

                                        control={control} as={FormGroupInput} id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]} /> */}
            </Col>
          );
        }

        case 'Double':
          return (
            <Col lg={xl} xl={xl} md={xl}>
              <Form.Group controlId={fieldName}>
                <Form.Label>{name}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder={name}
                  {...fieldInvalidProps}
                  {...register(fieldName, {
                    ...fieldRequireProps
                  })}
                  id={fieldName}
                  disabled={isDisable}
                  name={fieldName}
                  label={`${name}`}
                  defaultValue={data[fieldName]}
                />
                <Form.Control.Feedback type="invalid">
                  {requireMessage}
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group controlId={fieldName}>
                                    <Form.Label>{name}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={fieldName}
                                        {...register(fieldName)}
                                        id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]}
                                    />

                                </Form.Group> */}

              {/* <FormInput
                                    {...register(fieldName)}

                                    control={control} as={FormGroupInput} id={fieldName} name={fieldName} label={`${name}`} defaultValue={data[fieldName]} /> */}
            </Col>
          );

        case 'selectx':
          let { options } = field;
          return (
            <FormGroup key={name}>
              {/* <Label htmlFor={name}>{title}<span style={required ? { color: 'red' } : {}}> *</span></Label> */}
              <Form.Label>
                {title}
                <span style={required ? { color: 'red' } : {}}> *</span>
              </Form.Label>
              {/* <Input
                                type='select'
                                name={name}
                                {...register(name)}
                                onChange={e => setValue(name, e.target.value)}

                            //innerRef={register}
                            >

                                {renderOptions(options)}
                            </Input> */}

              <Form.Select
                {...register(name)}
                {...register(name)}
                onChange={e => setValue(name, e.target.value)}
              >
                {renderOptions(options, data[fieldName])}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {requireMessage}
              </Form.Control.Feedback>
            </FormGroup>
          );

        case 'selectCode':
          return (
            <>
              <Col lg={xl} xl={xl} md={xl}>
                {optionList && (
                  <FormGroup key={fieldName}>
                    {/* <Label htmlFor={name}>{title}<span style={required ? { color: 'red' } : {}}> *</span></Label> */}
                    <Form.Label>{name}</Form.Label>

                    <Form.Select
                      // {...register(`${fieldName}`)}
                      // {...register(`${fieldName}`)}
                      {...fieldInvalidProps}
                      {...register(fieldName, {
                        ...fieldRequireProps
                      })}
                      onChange={e => setValue(`${fieldName}`, e.target.value)}
                    >
                      {renderOptionsString(optionList, data[fieldName])}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {requireMessage}
                    </Form.Control.Feedback>
                  </FormGroup>
                )}
              </Col>
            </>
          );
        case 'Checkbox':
          let dc = {};
          if (getValues(`${fieldName}`) != undefined) {
            dc['defaultChecked'] = data[fieldName] == 'Y'; //getValues(`${fieldName}`);
            console.log(
              'defaultChecked.............' + getValues(`${fieldName}`)
            );
          }

          return (
            <>
              <Col lg={xl} xl={xl} md={xl}>
                {/* <Col xs={lg}>
                <Form.Group controlId={fieldName}> */}
                {/* <Form.Label>{name}</Form.Label> */}
                <Form.Check
                  className="mt-3"
                  inline
                  type="checkbox"
                  id={fieldName}
                  label={name}
                  //defaultChecked={data[fieldName] == "Y"}
                  {...dc}
                  onChange={e => {
                    console.log(`${fieldName} : ${e.target.checked}`);
                    setValue(`${fieldName}`, e.target.checked ? 'Y' : 'N');
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {requireMessage}
                </Form.Control.Feedback>
                {/* </Form.Group>
              </Col> */}
              </Col>
            </>
          );
        case 'Radio':
          return (
            <>
              <Col lg={xl} xl={xl} md={xl}>
                <Form.Label>{name}</Form.Label>
                <Form.Group controlId={fieldName}>
                  {optionList.map(child => {
                    // console.log(child);
                    console.log(fieldName + ' : ' + getValues(`${fieldName}`));
                    // console.log(child.itemValue);

                    let dc = {};
                    if (getValues(`${fieldName}`) != undefined) {
                      dc['defaultChecked'] =
                        child.itemValue == getValues(`${fieldName}`);
                    }
                    return (
                      <Form.Check
                        inline
                        type="radio"
                        label={child.itemLabel}
                        name={fieldName}
                        id={fieldName || child.itemValue}
                        // defaultChecked={
                        //   child.itemValue == getValues(`${fieldName}`)
                        // }
                        {...dc}
                        onChange={e => {
                          if (e.target.checked) {
                            console.log(
                              child.itemLabel +
                              ' : ' +
                              child.itemValue +
                              ' = ' +
                              getValues(`${fieldName}`)
                            );
                            setValue(`${fieldName}`, child.itemValue);
                          }
                        }}
                      />
                    );
                  })}

                  <Form.Control.Feedback type="invalid">
                    {requireMessage}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </>
          );
        default:
          return (
            <>
              <Col lg={xl} xl={xl} md={xl}>
                {optionList && (
                  <FormGroup key={fieldName}>
                    {/* <Label htmlFor={name}>{title}<span style={required ? { color: 'red' } : {}}> *</span></Label> */}
                    <Form.Label>{name}</Form.Label>

                    <Form.Select
                      // {...register(`${fieldName}`)}
                      // {...register(`${fieldName}`)}

                      {...fieldInvalidProps}
                      {...register(fieldName, {
                        ...fieldRequireProps
                      })}
                      onChange={e => setValue(`${fieldName}`, e.target.value)}
                    >
                      {renderOptions(optionList, data[fieldName])}
                    </Form.Select>
                  </FormGroup>
                )}
              </Col>
            </>
          );
      }
    });
  };
  let { title, fields } = refs;
  return (
    <>
      {/* <h4>{title}</h4> */}
      <Row>{renderFields(fields)}</Row>
    </>
  );
}
