import axios from 'axios';
import { createContext, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
//import { systemEnv } from 'config';
//import fetchWrapper from './jwtInterceptor';
// const { REACT_APP_API_URL } = process.env;
import { baseURL, apiBaseURL, systemEnv } from 'common-helpers/env-common';
const { REACT_APP_ENV } = process.env;

const AuthContext = createContext();
//const apiBaseURL = '/apis';

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // if (localStorage.getItem("tokens")) {
    //   let tokens = JSON.parse(localStorage.getItem("tokens"));
    //   return jwt_decode(tokens.accessToken);
    // }
    if (Cookies.get(`${systemEnv}tokens`)) {
      let tokens = JSON.parse(Cookies.get(`${systemEnv}tokens`));
      return jwt_decode(tokens.accessToken);
    }
    return null;
  });

  const navigate = useNavigate();

  const login = async payload => {

    //console.log('login >>>', payload);

    const apiResponse = await axios.post(
      `${apiBaseURL}/auth0/api/authenticate/login`,
      payload
    );

    //console.log('apiResponse>>', apiResponse)

    //console.log('apiResponse >>', apiResponse)
    //localStorage.setItem("tokens", JSON.stringify(apiResponse.data));
    Cookies.set(`${systemEnv}tokens`, JSON.stringify(apiResponse.data), { expires: 3600 });
    Cookies.set(`${systemEnv}refreshingData`, JSON.stringify({ isRefreshingToken: false }), { expires: 3600 });
    setUser(jwt_decode(apiResponse.data.accessToken));
    //window.location.href = `${REACT_APP_ENV == 'local' ? '/' : `${baseURL}/dashboard?ts=${Date.now()}`}`;
    //navigate(`${REACT_APP_ENV == 'local' ? '/' : `${baseURL}/dashboard?ts=${Date.now()}`}`);
    //setUser(jwt_decode(apiResponse.data.token));
    navigate(`/dashboard?ts=${Date.now()}`);
  };
  const logout = async () => {
    // invoke the logout API call, for our NestJS API no logout API
    Cookies.remove(`${systemEnv}tokens`);
    //localStorage.removeItem("tokens");
    setUser(null);
    //window.location.href = `${REACT_APP_ENV == 'local' ? '/landing' : baseURL}/?ts=${Date.now()}`;
    //navigate(`${REACT_APP_ENV == 'local' ? '/landing' : baseURL}/?ts=${Date.now()}`);
    navigate(`/?ts=${Date.now()}`);
  };
  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
