export const version = '1.0.1';//'3.5.1';
export const versionNumber = '1.0.11';//'3.5.1';
export const moduleName = "HP-PRO";
export const systemName = "HP-PRO 2024";


export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  navbarPosition: 'vertical',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '฿',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'inverted',
  isShowIconLogo: false,
  isShowPurchase: false,
  isShowSetting: false,
};

const { REACT_APP_ENV
} = process.env;

export const systemEnv = (REACT_APP_ENV === "local" ? "Local" : (REACT_APP_ENV === "production" ? "Prod" : (REACT_APP_ENV === "staging" ? "Staging" : (REACT_APP_ENV === "development" ? "Develop" : "Develop"))));


export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
