import React, { useEffect, useMemo, useState } from 'react';
import { Card, Dropdown, Row, Col } from 'react-bootstrap';
import Header from './Header';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from '../advance-table/AdvanceTableFooter';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import Pagination from 'components/common/pagination';
import Loader from 'components/common/Loader';

const DbGrid = ({
  isParent,
  onHeaderCreate,
  headerTitle,
  availableHeader,
  pageDatas,
  // onSearch,
  columns,
  datas,
  searchText,
  availableCreate = true,
  createLink
}) => {
  const onHeaderSearch = ({ target }) => {
    const keyword = target.value;
    let searchCriteria = {
      criteria: {
        searchText: keyword
      },
      pageIndex: 1,
      pageSize: pageDatas.perPage
    };

    if (isParent) {
      console.log('######=========== > onSearch');
      pageDatas.onSearch(searchCriteria);
    } else {
      pageDatas.onGetByParentId(pageDatas.parentId, searchCriteria);
      console.log('######=========== > onGetByParentId');

      //pageDatas.parentId
    }
  };

  useEffect(() => {
    console.log('######=========== >  (###2) DBGrid');
    console.log(
      '######=========== >  (###2) pageDatas.loadingPage >>>>>> ',
      pageDatas.loadingPage
    );
    console.log(
      '######=========== >  (###2) pageDatas.datas >>>>>> ',
      pageDatas.datas
    );
    console.log(pageDatas);
  }, [pageDatas]);

  // const baseLink = '/members';

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={datas}
      selection={availableHeader && availableCreate}
      sortable
      pagination
      perPage={pageDatas.perPage}
    >
      <Card className="mb-3">
        <Card.Header>
          {availableHeader && (
            <Header
              table
              moduleTitle={headerTitle}
              onSearch={onHeaderSearch}
              searchText={pageDatas?.searchCriteria?.criteria?.searchText || ''}
              //createLink={availableCreate ? `${baseLink}/create` : null}
              createLink={availableCreate ? createLink : null}
              onHeaderCreate={onHeaderCreate}
            />
          )}
        </Card.Header>
        <Card.Body className="p-0">
          {pageDatas?.loadingPage ? (
            <Loader />
          ) : (
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          )}

          <Row noGutters className="px-1 py-3 flex-center">
            <Col xs="auto">
              {!pageDatas.loading && (
                <Pagination
                  totalRecords={pageDatas?.totalRecord}
                  pageLimit={pageDatas?.perPage}
                  pageNeighbours={1}
                  pageIndex={pageDatas?.pageIndex}
                  onPageChanged={pageDatas?.onPageChanged}
                />
              )}
            </Col>

            <Col xs="auto">
              {!pageDatas.loading && (
                <AdvanceTableFooter
                // page={pageDatas?.datas}
                // pageSize={pageDatas?.perPage}
                // pageIndex={pageDatas?.pageIndex}
                // rowCount={pageDatas?.totalRecord}
                // table
                // rowInfo
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </AdvanceTableWrapper>
  );
};
export default DbGrid;
