import React, { useEffect, useMemo, useState } from 'react';
import { Card, Dropdown, Row, Col } from 'react-bootstrap';
import { orderList } from 'data/ecommerce/orderList';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from './Header';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import { settings } from 'config';

import Pagination from 'components/common/pagination';
import Loader from 'components/common/Loader';
import DbGrid from 'components/common/dbgrid';
import moment from 'moment';
import IconButton from 'components/common/IconButton';
import Swal from 'sweetalert2';
import { useSystemUserRole } from 'hooks/useSystemUserRole';

//import uuid from 'uuid/v1';

// const moduleName = 'Supplier';
// const moduleNameTitle = 'Supplier';
// const moduleNameLower = moduleName[0].toLowerCase() + moduleName.slice(1);

const SystemUserRole = () => {
  const [listedColumns, setListedColumns] = useState([]);

  const pageData = useSystemUserRole();

  const baseLink = '/system-user-role';

  useMemo(() => {
    const cols = pageData?.listedColumns?.map(
      ({ fieldName, fieldType, fieldLabelLocale }) => {
        return {
          accessor: fieldName,
          Header: fieldLabelLocale || fieldName,
          headerProps: { className: 'pe-1' },
          cellProps: {
            className: `py-2 ${fieldType == 'Double' ? 'text-end' : ''}`
          },
          Cell: rowData => {
            return (
              <strong>
                {fieldType == 'DateTime' &&
                rowData.row.original[fieldName] != null
                  ? moment(rowData.row.original[fieldName]).format('DD/MM/yyyy')
                  : rowData.row.original[fieldName]}
              </strong>
            );
          }
        };
      }
    );
    setListedColumns(cols);
  }, [pageData?.listedColumns]);

  //   const onSearch = ({ target }) => {
  //     const keyword = target.value;
  //     let searchCriteria = {
  //       criteria: {
  //         searchText: keyword
  //       },
  //       pageIndex: 1,
  //       pageSize: pageDatas.perPage
  //     };
  //     pageDatas.onSearch(searchCriteria);
  //   };

  const buttonColumns = [
    {
      accessor: 'status',
      Header: 'Status',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'fs-0'
      },
      Cell: rowData => {
        const { documentStatusCode } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              success: documentStatusCode === '06', //completed
              primary: documentStatusCode === '03', //processing
              dark: documentStatusCode === '08', //Revise
              warning:
                documentStatusCode === '02' || documentStatusCode === '01', // pending
              info: documentStatusCode === '04', // Review
              // light: documentStatusCode === '01', // Draft
              secondary: documentStatusCode === 'onhold', //onhold
              danger: documentStatusCode === '07' //Rejected
            })}
            className="d-block"
          >
            {documentStatusCode === '01' && 'Draft'}
            {documentStatusCode === '02' && 'Pending'}
            {documentStatusCode === '03' && 'Processing'}
            {documentStatusCode === '04' && 'Review'}
            {documentStatusCode === '05' && 'On-Hold'}
            {documentStatusCode === '06' && 'Completed'}
            {documentStatusCode === '07' && 'Rejected'}
            {documentStatusCode === '08' && 'Revise'}
            <FontAwesomeIcon
              icon={classNames({
                check: documentStatusCode === '06',
                redo:
                  documentStatusCode === '03' ||
                  documentStatusCode === '07' ||
                  documentStatusCode === '08',
                stream:
                  documentStatusCode === '02' ||
                  documentStatusCode === '01' ||
                  documentStatusCode === '04',
                ban: documentStatusCode === '05'
              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <>
            <IconButton
              variant="syscore-default"
              size="sm"
              icon="edit"
              transform="shrink-3"
              className="mx-2"
              as={Link}
              to={`${baseLink}/${id}`}
            />
            <IconButton
              variant="syscore-default"
              size="sm"
              icon="trash-alt"
              transform="shrink-3"
              className="mx-2"
              onClick={() => {
                Swal.fire({
                  title: 'คุณต้องการลบรายการนี้หรือไม่?',
                  text: '',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, delete it!'
                }).then(result => {
                  if (result.isConfirmed) {
                    pageData.onDeleteById(rowData.row.original.id);
                    Swal.fire({
                      title: 'ลบรายการของคุณเรียบร้อยแล้ว',
                      text: ``,
                      icon: 'success',
                      showConfirmButton: false,
                      timer: 1200
                    });
                  }
                });
              }}
            />
          </>
        );
      }
    }
  ];

  return (
    <>
      <DbGrid
        headerTitle={'System User Role'}
        isParent={true}
        availableHeader={true}
        columns={[...listedColumns, ...buttonColumns]}
        pageDatas={pageData}
        // onSearc={onSearch}
        datas={pageData?.datas}
        searchText={pageData?.searchCriteria?.criteria?.searchText}
        createLink={'/system-user-role/create'}
      />
    </>
  );
};

export default SystemUserRole;
