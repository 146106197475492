import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import CustomDateInput from 'components/common/CustomDateInput';
import PropTypes from 'prop-types';
import { timezones } from 'data/events/timezones';
import AdvanceSelect from 'components/common/advance-select';
import AdvanceSelect2 from 'components/common/advance-select2';
import { DynamicForm } from 'components/common/dynamic-form';
import { ArrayForm } from 'components/common/array-form';

const SystemUserRoleDetail = ({
  // register,
  // setValue,
  handleform,
  moduleName,
  control,
  fields,
  data,
  refs
}) => {
  return (
    <Card className="mb-2">
      <Card.Header as="h5">System User Role Detail</Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-1 gy-0">
          <Col md="12">
            {refs && (
              <DynamicForm
                handleform={handleform}
                moduleName={moduleName}
                control={control}
                refs={refs}
                data={data}
              />
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

// SystemUserRoleDetail.propTypes = {
//   register: PropTypes.func.isRequired,
//   setValue: PropTypes.func.isRequired
// };
export default SystemUserRoleDetail;
