/* eslint-disable */
const {
    REACT_APP_API_URL_STAGING, REACT_APP_API_URL_PROD, REACT_APP_ENV, REACT_APP_API_URL_DEV, REACT_APP_API_URL_LOCAL,
    REACT_APP_URL_STAGING, REACT_APP_URL_PROD, REACT_APP_URL_DEV, REACT_APP_URL_LOCAL
} = process.env;

const apiBaseURL = (REACT_APP_ENV === "local" ? REACT_APP_API_URL_DEV : (REACT_APP_ENV === "production" ? REACT_APP_API_URL_PROD : (REACT_APP_ENV === "staging" ? REACT_APP_API_URL_STAGING : (REACT_APP_ENV === "development" ? REACT_APP_API_URL_DEV : REACT_APP_API_URL_DEV))));
const baseURL = (REACT_APP_ENV === "local" ? REACT_APP_URL_LOCAL : (REACT_APP_ENV === "production" ? REACT_APP_URL_PROD : (REACT_APP_ENV === "staging" ? REACT_APP_URL_STAGING : (REACT_APP_ENV === "development" ? REACT_APP_URL_DEV : REACT_APP_URL_LOCAL))));
const envName = (REACT_APP_ENV === "local" ? REACT_APP_URL_LOCAL : (REACT_APP_ENV === "production" ? "" : (REACT_APP_ENV === "staging" ? " - UAT" : (REACT_APP_ENV === "development" ? " - DEV" : " - DEV"))));
const systemEnv = (REACT_APP_ENV === "local" ? "Local" : (REACT_APP_ENV === "production" ? "Prod" : (REACT_APP_ENV === "staging" ? "Staging" : (REACT_APP_ENV === "development" ? "Develop" : "Develop"))));


export { apiBaseURL, baseURL, envName, systemEnv };